<template>
<div id="pano" style="flex: 1;width:100%;height:100%;">
</div>
</template>
<script>
export default {
  //http://localhost:5000/app/config/tour/1396288543642357760
  mounted(){
  embedpano({ xml:"http://localhost:5000/1396288543642357760.xml", target:"pano", html5:"only", mobilescale:1.0, passQueryParameters:true});
	
  }
    
}
</script>
<style lang="scss">
    
</style>